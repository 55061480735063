import { gql } from '@apollo/client';

const RepresentativeFragment = gql`
  fragment RepresentativeFragment on User {
    _id
    privilege
    firstName
    lastName
    email
    photo
    phone {
      mobile
      local
    }
  }
`;

const TransactionFragment = gql`
  fragment TransactionFragment on Transaction {
    _id
    type
    amount
    description
    createdAt
    benefit {
      _id
      name
    }
  }
`;

const QuizAttemptFragment = gql`
  fragment QuizAttemptFragment on QuizAttempt {
    createdAt
    points
    quiz {
      _id
      name
      photo
    }
    answers {
      _id
      question {
        _id
        name
      }
      points
    }
  }
`;

const BusinessFragment = gql`
  fragment BusinessFragment on Business {
    _id
    name
    rif
    clientCode
    address {
      state
      municipality
      neighborhood
      avenue
      local
      reference
    }
    route {
      _id
      representatives {
        ...RepresentativeFragment
      }
    }
    region {
      _id
      representatives {
        ...RepresentativeFragment
      }
    }
    zone {
      _id
      representatives {
        ...RepresentativeFragment
      }
    }
  }
  ${RepresentativeFragment}
`;

const ClientFragment = gql`
  fragment ClientFragment on Client {
    _id
    dni
    dniType
    points
    clientCode
    quizzes {
      ...QuizAttemptFragment
    }
    pointsHistory {
      ...TransactionFragment
    }
    business {
      ...BusinessFragment
    }
  }
  ${QuizAttemptFragment}
  ${TransactionFragment}
  ${BusinessFragment}
`;

export const UserFragment = gql`
  fragment UserFragment on User {
    _id
    slug
    firstName
    lastName
    privilege
    email
    birthDate
    photo
    token
    phone {
      mobile
      local
    }
    client {
      ...ClientFragment
    }
  }
  ${ClientFragment}
`;

export const PageInfoFragment = gql`
  fragment PageInfoFragment on PaginationInfo {
    itemCount
    hasNextPage
    hasPreviousPage
    pageCount
  }
`;

export const BenefitsFragment = gql`
  fragment BenefitsFragment on Benefit {
    _id
    slug
    name
    points
    photos
    description
    quantity
    categories {
      _id
      name
    }
  }
`;

export const QuizzesFragment = gql`
  fragment QuizzesFragment on Quiz {
    _id
    name
    description
    photo
  }
`;

export const QuizFragment = gql`
  fragment QuizFragment on Quiz {
    _id
    name
    description
    video
    file
    questions {
      _id
      name
      type
      points
      options {
        _id
        name
        correct
      }
    }
  }
`;

export const AvailabilityFragment = gql`
  fragment AvailabilityFragment on Availability {
    _id
    year
    month
    illicit
  }
`;
