import React from 'react';
import { User } from '../models';

interface AppContextType {
  user?: User;
  setUser?: React.Dispatch<React.SetStateAction<User>>;
}

const AppContext = React.createContext<AppContextType>({});

export default AppContext;
