import { gql } from '@apollo/client';
import { UserFragment } from './fragments';

export const REGISTER_CLIENT = gql`
  mutation REGISTER_CLIENT($data: RegisterInput!) {
    registerClient(data: $data) {
      _id
      firstName
      lastName
      email
    }
  }
`;

export const SIGN_IN = gql`
  mutation SIGN_IN($data: SignInInput!) {
    signIn(data: $data) {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const SIGN_OUT = gql`
  mutation SIGN_OUT {
    signOut {
      success
    }
  }
`;

export const CREATE_QUIZ_ATTEMPT = gql`
  mutation CREATE_QUIZ_ATTEMPT($data: CreateQuizAttemptInput!) {
    createQuizAttempt(data: $data) {
      _id
    }
  }
`;

export const EXCHANGE_BENEFIT = gql`
  mutation EXCHANGE_BENEFIT($data: ExchangeBenefitInput!) {
    exchangeBenefit(data: $data) {
      _id
      amount
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UPDATE_PROFILE($data: UpdateClientInput!) {
    updateProfile(data: $data) {
      slug
    }
  }
`;

export const SCAN_QR_CODE = gql`
  mutation SCAN_QR_CODE($data: ScanQRCodeInput!) {
    scanQRCode(data: $data) {
      success
      msg
      register
    }
  }
`;

export const UPDATE_MANY_NOTIFICATIONS = gql`
  mutation UPDATE_MANY_NOTIFICATIONS(
    $filter: FilterUpdateManyNotificationInput!
    $record: UpdateManyNotificationInput!
  ) {
    updateNotifications(filter: $filter, record: $record) {
      numAffected
    }
  }
`;

export const SUBMIT_FORM = gql`
  mutation SUBMIT_FORM($record: CreateOneFormSubmissionInput!) {
    createFormSubmission(record: $record) {
      record {
        _id
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation RESET_PASSWORD($data: ResetPasswordInput!) {
    resetPassword(data: $data) {
      success
      err
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation CHANGE_PASSWORD($data: ChangePasswordInput!) {
    changePassword(data: $data) {
      success
      err
    }
  }
`;
