import React from 'react';
import Link from 'next/link';

interface SidebarLinkProps {
  href: string;
  as: string;
  text: string;
  active: boolean;
  icon: React.ReactNode;
}

export default function SidebarLink({
  href,
  as,
  text,
  icon,
  active,
}: SidebarLinkProps) {
  return (
    <li
      className={`w-8 h-8 md:w-24 md:h-20 md:p-4 flex flex-col flex-wrap mx-auto ${
        active ? 'md:border-r-2 border-primary-500' : ''
      }`}
    >
      <Link
        href={href}
        as={as}
        className={`text-center mx-auto my-auto ${
          active ? 'text-primary-500' : 'text-gray-500'
        }`}
      >
        {icon}
        <span className="text-center w-full hidden md:inline-block md:text-sm ">
          {text}
        </span>
      </Link>
    </li>
  );
}
