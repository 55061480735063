import React from 'react';
import ContextProvider from '../context/Provider';
import Navbar from './Navbar';
import Sidebar from './Sidebar/Sidebar';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <ContextProvider>
      <>
        <Sidebar />
        <section className="relative md:ml-24 bg-gray-100 min-h-screen">
          <Navbar />
          <article className="px-8 py-8 min-h-screen">{children}</article>
          <footer className="w-full px-8 py-8 text-gray-500">
            <p className="hidden md:block">
              © {new Date().getFullYear()} Plataforma Unidos. Desarrollado por{' '}
              <a
                href="https://avilatek.dev"
                target="_blank"
                rel="noreferrer"
                className="text-green-700"
              >
                Avila Tek
              </a>
            </p>
          </footer>
        </section>
      </>
    </ContextProvider>
  );
}
