import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { CURRENT_USER } from '../graphql/queries';
import { SIGN_OUT } from '../graphql/mutations';
import { User } from '../models';
import AppContext from './index';

interface ContextProviderProps {
  children: React.ReactNode;
}

export default function ContextProvider({ children }: ContextProviderProps) {
  const router = useRouter();
  const [signOut] = useMutation(SIGN_OUT);
  const { data, loading, error } = useQuery<{ currentUser: User }>(
    CURRENT_USER,
    { fetchPolicy: 'network-only' }
  );
  const [user, setUser] = React.useState<User>(null);
  React.useEffect(
    function userChanged() {
      if (!loading && data) {
        if (typeof window !== 'undefined') {
          sessionStorage.setItem(
            'token',
            JSON.stringify(data?.currentUser?.token)
          );
        }
        setUser(data?.currentUser);
      }
      if (!loading && !data) {
        router.push('/sign-in');
      }
      if (!loading && !data?.currentUser) {
        router.push('/sign-in');
      }
    },
    [data, loading]
  );
  React.useEffect(() => {
    if (user) {
      if (user.privilege !== 0) {
        setUser(null);
        signOut();
        if (typeof window !== 'undefined') {
          sessionStorage.removeItem('token');
        }
        router.push('/');
      }
    }
  }, [user]);
  const context = React.useMemo(() => ({ user, setUser }), [user, setUser]);
  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}
