import React from 'react';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { SIGN_OUT } from '../../graphql/mutations';

interface ProfileProps {
  className?: string;
}

export default function Profile({ className = '' }: ProfileProps) {
  const [signOut] = useMutation(SIGN_OUT);
  const router = useRouter();
  const _signOut = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      // await signOut();
      // await router.push('/sign-in');
      await router.push('/app/profile');
    } catch (err: any) {
      console.log(err);
    }
  };
  return (
    <button className={`m-auto ${className}`} type="button" onClick={_signOut}>
      <img
        className="w-12 h-12 md:w-14 md:h-14 rounded-full shadow-xl object-contain border-2 border-primary-500"
        src="/img/profile.jpg"
        alt="Logo Unidos"
      />
    </button>
  );
}
