import React from 'react';
import { useRouter } from 'next/router';
import routes from '../../routes';
import useUser from '../../hooks/useUser';
import Profile from './Profile';
import SidebarLink from './SidebarLink';

export default function Sidebar() {
  const router = useRouter();
  const [user] = useUser();
  return (
    <nav className="bg-white flex flex-row flex-wrap fixed left-0 bottom-0 md:flex-col md:min-h-screen md:h-screen md:max-h-screen md:w-24 md:top-0 w-full z-20">
      <div className="hidden md:flex w-full h-20">
        <img
          className="w-16 h-16 m-auto object-contain"
          src="/img/logo.png"
          alt="Logo Unidos"
        />
      </div>
      <div className="flex-1 top-0 left-0 overflow-y-auto w-full py-6 md:py-0 block static">
        <ul className="flex md:flex-col flex-wrap w-full">
          {routes.map((route) => {
            if (route.as === '/app') {
              return (
                <SidebarLink
                  {...route}
                  key={route.as}
                  active={route.as === router.asPath}
                />
              );
            }
            return (
              <SidebarLink
                {...route}
                key={route.as}
                active={router.asPath
                  .substring(4)
                  .includes(route.as.substring(4))}
              />
            );
          })}
        </ul>
      </div>
      <div className="w-auto h-10 md:h-20 ml-auto md:ml-0 hidden md:flex md:mt-auto">
        <Profile className="relative" />
      </div>
    </nav>
  );
}
