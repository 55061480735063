import { gql } from '@apollo/client';
import {
  UserFragment,
  PageInfoFragment,
  BenefitsFragment,
  QuizzesFragment,
  QuizFragment,
  AvailabilityFragment,
} from './fragments';

export const CHECK_CLIENT_CODE = gql`
  query CHECK_CLIENT_CODE($data: CheckClientCodeInput!) {
    checkClientCode(data: $data) {
      isRegister
      email
    }
  }
`;

export const CURRENT_USER = gql`
  query CURRENT_USER {
    currentUser {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GET_BENEFITS = gql`
  query GET_BENEFITS(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyBenefitInput!
  ) {
    categories(filter: { active: true }) {
      _id
      name
    }
    benefitPagination(page: $page, perPage: $perPage, filter: $filter) {
      pageInfo {
        ...PageInfoFragment
      }
      items {
        ...BenefitsFragment
      }
    }
  }
  ${PageInfoFragment}
  ${BenefitsFragment}
`;

export const GET_QUIZZES = gql`
  query GET_QUIZZES(
    $page: Int!
    $perPage: Int!
    $filter: FilterFindManyQuizInput!
  ) {
    quizPagination(page: $page, perPage: $perPage, filter: $filter) {
      pageInfo {
        ...PageInfoFragment
      }
      items {
        ...QuizzesFragment
      }
    }
  }
  ${PageInfoFragment}
  ${QuizzesFragment}
`;

export const GET_QUIZ = gql`
  query GET_QUIZ($filter: FilterFindOneQuizInput!) {
    quiz(filter: $filter) {
      ...QuizFragment
    }
  }
  ${QuizFragment}
`;

export const GET_QUIZ_ATTEMPT = gql`
  query GET_QUIZ_ATTEMPT($filter: FilterFindOneQuizAttemptInput!) {
    quizAttempt(filter: $filter) {
      _id
      points
      quiz {
        name
        description
      }
      answers {
        question {
          name
        }
        correct {
          name
        }
        points
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query GET_PROFILE {
    currentUser {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const GET_FAQS = gql`
  query GET_FAQS {
    FAQs {
      _id
      name
      description
    }
  }
`;

export const GET_DASHBOARD_INFO = gql`
  query GET_DASHBOARD_INFO {
    clientDashboard {
      accounts {
        target
        percentage
        icon
      }
      availability {
        target
        percentage
        icon
      }
      volume {
        aspirationalPremium {
          target
          percentage
          icon
        }
        premium {
          target
          percentage
          icon
        }
        valueForMoney {
          target
          percentage
          icon
        }
      }
      points
      hasIllicit
      representatives {
        _id
        firstName
        lastName
        email
        photo
        phone {
          mobile
        }
      }
      notifications {
        _id
      }
      benefits {
        ...BenefitsFragment
      }
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragment}
  ${BenefitsFragment}
`;

export const GET_GRAPHS_INFO = gql`
  query GET_GRAPHS_INFO {
    clientGraphsInfo {
      accounts {
        _id
        year
        month
        value
      }
      availabilities {
        _id
        year
        month
        points {
          percentage
        }
      }
      volumes {
        _id
        aspirationalPremium
        premium
        valueForMoney
        year
        month
      }
      transactions {
        _id
        type
        amount
        createdAt
      }
      volumeSticks {
        _id
        aspirationalPremium
        premium
        valueForMoney
        date
      }
    }
  }
`;

export const GET_NOTIFICATION_COUNT = gql`
  query GET_NOTIFICATION_COUNT($filter: FilterCountNotificationInput!) {
    notificationCount(filter: $filter)
  }
`;

export const GET_NOTIFICATIONS = gql`
  query GET_NOTIFICATIONS($filter: FilterFindManyNotificationInput!) {
    notifications(filter: $filter) {
      _id
      name
      message
      type
      active
      createdAt
    }
  }
`;

export const SEARCH_BENEFIT = gql`
  query SEARCH_BENEFIT($filter: SearchBenefitInput!) {
    searchBenefit(filter: $filter) {
      ...BenefitsFragment
    }
  }
  ${BenefitsFragment}
`;

export const SEARCH_BENEFITS_CATEGORIES = gql`
  query SEARCH_BENEFITS_CATEGORIES($filter: SearchBenefitByCategoriesInput!) {
    searchBenefitByCategories(filter: $filter) {
      ...BenefitsFragment
    }
  }
  ${BenefitsFragment}
`;

export const SEARCH_QUIZ = gql`
  query SEARCH_QUIZ($filter: SearchQuizInput!) {
    searchQuiz(filter: $filter) {
      ...QuizzesFragment
    }
  }
  ${QuizzesFragment}
`;

export const HAS_ILLICIT = gql`
  query HAS_ILLICIT($filter: HasIllicitInput) {
    hasIllicit(filter: $filter) {
      ...AvailabilityFragment
    }
  }
  ${AvailabilityFragment}
`;
