import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { SIGN_OUT } from '../graphql/mutations';
import { GET_NOTIFICATION_COUNT } from '../graphql/queries';
import useNotify from '../hooks/useNotify';
import useUser from '../hooks/useUser';
import formatMoney from '../lib/formatMoney';
import Profile from './Sidebar/Profile';
import NotificationIcon from './NotificationIcon';

export default function Navbar() {
  const [user, setUser] = useUser();
  const [signOut] = useMutation(SIGN_OUT);
  const router = useRouter();
  const notify = useNotify();
  const onSignOut = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    try {
      e.preventDefault();
      await signOut();
      if (typeof window !== 'undefined') {
        sessionStorage.removeItem('token');
      }
      setUser(null);
      await router.push('/sign-in');
    } catch (err: any) {
      notify(err.message, 'danger');
    }
  };
  return (
    <header>
      <nav className="flex flex-row flex-wrap px-4 py-2 md:hidden bg-white shadow-md">
        <img
          className="w-12 h-12 mr-2 object-contain"
          src="/img/logo.png"
          alt="Logo Unidos"
        />
        <div className="flex-auto flex flex-row flex-wrap">
          <Profile className="mr-0" />
        </div>
      </nav>
      <nav className="hidden md:flex w-full bg-white flex-row flex-wrap px-8 h-16">
        <div className="my-auto">
          <p>
            Bienvenido(a) {user?.firstName ?? 'John'} {user?.lastName ?? 'Doe'}
          </p>
        </div>
        <div className="ml-auto my-auto flex flex-row flex-wrap">
          <p className="mr-4">
            <span className="text-primary-500">
              {formatMoney(user?.client?.points ?? 0)}
            </span>{' '}
            puntos
          </p>
          {/* <button type="button" className="mr-2">
            <svg
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
              <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </button> */}
          <NotificationIcon _id={user?._id ?? ''} />
          <button type="button" className="mr-2" onClick={onSignOut}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1}
            >
              <path d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>
          </button>
        </div>
      </nav>
    </header>
  );
}
